import { useMemo } from 'react';

import type { CurrentlyReadingDocument } from '../mobile-library/ReaderContainer/types';
import type { FirstClassDocument } from '../types';


export const useIsEnhancedTranscript = (doc: FirstClassDocument | CurrentlyReadingDocument | null) => useMemo(
  () => Boolean(doc?.source_specific_data?.video?.show_enhanced_youtube_transcript),
  [doc],
);
