import { Core } from '@pdftron/webviewer';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { scrollToTtsPosition } from 'shared/foreground/contentFramePortalGateInternalMethods';
import { globalState } from 'shared/foreground/models';
import { useDocument, useIsPDFViewAsHTML } from 'shared/foreground/stateHooks';
import {
  updateDocumentTTSPosition,
} from 'shared/foreground/stateUpdaters/persistentStateUpdaters/documents/progressRelated';
import { fetchDocumentContent } from 'shared/foreground/stateUpdaters/transientStateUpdaters/documentContent';
import type { AnyDocument, DocumentWithTransientData, Pdf } from 'shared/types';
import { Category } from 'shared/types';
import { ShortcutId } from 'shared/types/keyboardShortcuts';
import {
  isDocumentWithParsedDocId,
  isDocumentWithPublishedDate,
  isDocumentWithUrl,
  isDocumentWithWordCount,
  isFirstClassDocument,
  isYouTubeUrl,
} from 'shared/typeValidators';
import formatPublishedDate from 'shared/utils/dates/formatPublishedDate';
import getDocumentAuthor from 'shared/utils/getDocumentAuthor';
import getDocumentTitle from 'shared/utils/getDocumentTitle';

import { AdaptiveHeaderContext } from '../../contexts';
import { useAppearanceStyleHotkeys } from '../../hooks/appearanceStyles';
import { useIsLeftSidebarHidden } from '../../hooks/hooks';
import { useKeyboardShortcut } from '../../hooks/useKeyboardShortcut';
import { getScrollLocation } from '../../hooks/useScrollLocation';
import { toggleZenMode } from '../../stateUpdaters/other';
import {
  hideLeftReaderViewSidebar,
  hideReaderViewSidebars,
  hideRightReaderViewSidebar,
} from '../../stateUpdaters/sidebars';
import { printDocumentContent } from '../../utils/printDocumentContent';
import { useShortcutsMap } from '../../utils/shortcuts';
import updateDocumentTitle from '../../utils/updateDocumentTitle';
import AdaptiveHeader, { useHeaderIsHidden, useVideoHeaderIsHidden } from '../AdaptiveHeader';
import { ArticleAside, PDFAside } from '../Aside';
import { DocumentContent } from '../DocumentContent';
import EpubTableOfContents from '../EpubTableOfContents';
import { clearFindInDocumentState } from '../FindInDocumentBar/findInDocument';
import { FindInDocumentBar } from '../FindInDocumentBar/FindInDocumentBar';
import { PdfTronContext, PDFViewer } from '../PDFViewer';
import TableOfContents from '../TableOfContents';
import styles from './DocumentReader.module.css';

type DocumentReaderProps = {
  docId?: string;
  documentPathPrefix: string;
  inboxDocumentIds: AnyDocument['id'][];
  parentPath: string;
  scrollableAncestorRef: React.MutableRefObject<HTMLElement>;
  onEndThresholdReached?: () => void;
};

export default function DocumentReader({
  docId = '',
  documentPathPrefix,
  inboxDocumentIds,
  parentPath,
  scrollableAncestorRef,
  onEndThresholdReached,
}: DocumentReaderProps) {
  const hideLeftPanelOnEnteringReadingView = globalState(
    useCallback((state) => state.client.hideLeftPanelOnEnteringReadingView, []),
  );
  const hideRightPanelOnEnteringReadingView = globalState(
    useCallback((state) => state.client.hideRightPanelOnEnteringReadingView, []),
  );
  const hidePanelsOnEnteringReadingView = useMemo(
    () => hideLeftPanelOnEnteringReadingView && hideRightPanelOnEnteringReadingView,
    [hideLeftPanelOnEnteringReadingView, hideRightPanelOnEnteringReadingView],
  );
  const [contentRoot, setContentRoot] = useState<HTMLDivElement | null>(null);
  const [currentFocusedElement, setCurrentFocusedElement] = useState<HTMLElement | null>(null);
  const [currentDoc, { isFetching: isFetchingDoc }] = useDocument(docId);

  // Try to fetch more docs if we are reading the last one in the list
  useEffect(() => {
    const lastDocId = inboxDocumentIds[inboxDocumentIds.length - 1];
    const isLastDoc = lastDocId === docId;

    if (isLastDoc && onEndThresholdReached) {
      onEndThresholdReached();
    }
  }, [inboxDocumentIds, docId, onEndThresholdReached]);

  useEffect(() => {
    if (hidePanelsOnEnteringReadingView) {
      hideReaderViewSidebars(true, { userInteraction: 'unknown' });
      return;
    }

    if (hideLeftPanelOnEnteringReadingView) {
      hideLeftReaderViewSidebar(true, { userInteraction: 'unknown' });
    }

    if (hideRightPanelOnEnteringReadingView) {
      hideRightReaderViewSidebar(true, { userInteraction: 'unknown' });
    }
  }, [
    docId,
    hidePanelsOnEnteringReadingView,
    hideLeftPanelOnEnteringReadingView,
    hideRightPanelOnEnteringReadingView,
  ]);

  const leftSidebarHidden = useIsLeftSidebarHidden();

  const isPDF = currentDoc?.category === Category.PDF;
  const showHTMLContentForPDF = useIsPDFViewAsHTML(currentDoc?.id);
  const isPDFView = useMemo(() => isPDF && !showHTMLContentForPDF, [isPDF, showHTMLContentForPDF]);

  const isDocMoreActionsDropdownOpen = globalState(
    useCallback((state) => state.isDocMoreActionsDropdownOpen, []),
  );
  const isYouTube = useMemo(
    () =>
      currentDoc && isDocumentWithUrl(currentDoc) && currentDoc?.url
        ? isYouTubeUrl(currentDoc.url) && !isDocMoreActionsDropdownOpen
        : false,
    [currentDoc, isDocMoreActionsDropdownOpen],
  );

  const isDistributable = Boolean(currentDoc && !currentDoc.non_distributable);

  // Load content in case it hasn't been preloaded already, or in the case that the document's is parsed_doc_id newly set
  useEffect(() => {
    if (!docId) {
      return;
    }
    fetchDocumentContent([docId]);
  }, [docId, currentDoc?.parsed_doc_id]);

  const shortcutsMap = useShortcutsMap();

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.ToggleZenMode],
    useCallback(async () => {
      await toggleZenMode({ userInteraction: 'keypress' });
    }, []),
    { description: 'Toggle focus mode' },
  );

  useAppearanceStyleHotkeys(isPDFView);

  const onRendered = useCallback((element: HTMLDivElement | null) => {
    setContentRoot(element);
  }, []);

  // Check for stored TTS doc data. If possible, scroll to it,
  // Then, save the new reading position and clear old TTS doc data.
  useEffect(() => {
    // scrollTimer and afterScrolling are used to emulate our 'scroll-end' event on mobile
    let scrollTimer: NodeJS.Timeout | number;
    const scrollableRoot = scrollableAncestorRef.current;

    const afterScrolling = () => {
      clearTimeout(scrollTimer);
      scrollTimer = setTimeout(async () => {
        scrollableRoot.removeEventListener('scroll', afterScrolling);
        if (!contentRoot) {
          return;
        }

        const newReadingPos = await getScrollLocation({
          contentRootRef: contentRoot,
          scrollableRootRef: scrollableRoot,
        });
        if (!newReadingPos) {
          return;
        }
        await updateDocumentTTSPosition(docId, undefined, newReadingPos, {
          eventName: 'reset-doc-tts-on-load',
          userInteraction: null,
        });
      }, 10);
    };

    if (currentDoc?.ttsPosition && scrollableRoot && contentRoot) {
      scrollToTtsPosition({
        contentContainer: contentRoot,
        scrollableRoot,
        isAutoScrollEnabled: true,
        skipIndicatorUpdate: false,
        ttsPosition: currentDoc.ttsPosition,
        useRepeatedLastWord: true,
      });
      scrollableRoot.addEventListener('scroll', afterScrolling);
    }
    return () => {
      scrollableRoot.removeEventListener('scroll', afterScrolling);
      clearTimeout(scrollTimer);
    };

    // When TTS is running, this hook would run every second which we don't want.
    // TTS can't be in this hook's dependencies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRoot, docId, scrollableAncestorRef]);

  const onNewFocusTarget = useCallback((target: HTMLElement | void) => {
    setCurrentFocusedElement(target || null);
  }, []);

  const tableOfContents = useMemo(() => {
    if (Boolean(docId) && contentRoot) {
      if (currentDoc?.category === Category.EPUB) {
        return (
          <EpubTableOfContents
            contentRoot={contentRoot}
            tocItems={currentDoc?.source_specific_data?.epub?.toc ?? []}
            currentFocusedElement={currentFocusedElement}
          />
        );
      } else {
        return (
          <TableOfContents contentRoot={contentRoot} currentFocusedElement={currentFocusedElement} />
        );
      }
    } else {
      return null;
    }
  }, [
    docId,
    contentRoot,
    currentDoc?.category,
    currentDoc?.source_specific_data?.epub?.toc,
    currentFocusedElement,
  ]);

  const title = useMemo(() => getDocumentTitle(currentDoc), [currentDoc]);
  const author = useMemo(() => getDocumentAuthor(currentDoc) || '', [currentDoc]);
  const publishedDate = useMemo(
    () =>
      currentDoc && isDocumentWithPublishedDate(currentDoc) && currentDoc.published_date
        ? formatPublishedDate(currentDoc.published_date)
        : '',
    [currentDoc],
  );

  useEffect(() => {
    if (currentDoc) {
      updateDocumentTitle(title);
    }
  }, [currentDoc, title]);

  const getWordCount = () => {
    if (currentDoc && isDocumentWithWordCount(currentDoc) && currentDoc.word_count) {
      return currentDoc.word_count;
    }

    return 0;
  };

  const getParsedDocId = () => {
    if (currentDoc && isDocumentWithParsedDocId(currentDoc) && currentDoc.parsed_doc_id) {
      return currentDoc.parsed_doc_id?.toString();
    }

    return undefined;
  };

  const [pdfBookmarks, setPdfBookmarks] = useState<Core.Bookmark[]>([]);
  const [customPDFPage, setCustomPDFPage] = useState<number | undefined>(undefined);

  useEffect(() => {
    setCustomPDFPage(undefined);
  }, [docId]);

  useKeyboardShortcut(
    shortcutsMap[ShortcutId.PrintDocument],
    useCallback(
      (e) => {
        if (isPDFView) {
          return;
        }

        e.preventDefault();

        if (!isDistributable) {
          return;
        }

        printDocumentContent({ docTitle: title, author, publishedDate });
      },
      [isPDFView, isDistributable, title, author, publishedDate],
    ),
    { description: 'Print document with annotations' },
  );

  const aside = useMemo(() => {
    if (isPDFView) {
      return (
        <PDFAside
          currentDoc={currentDoc}
          documentPathPrefix={documentPathPrefix}
          inboxDocumentIds={inboxDocumentIds}
          onBookmarkClicked={setCustomPDFPage}
          parentPath={parentPath}
          pdfBookmarks={pdfBookmarks}
          sidebarsHidden={leftSidebarHidden}
          leftSidebarHidden={leftSidebarHidden}
        />
      );
    } else {
      return (
        <ArticleAside
          currentDoc={currentDoc}
          documentPathPrefix={documentPathPrefix}
          inboxDocumentIds={inboxDocumentIds}
          parentPath={parentPath}
          sidebarsHidden={leftSidebarHidden}
          tableOfContents={tableOfContents}
        />
      );
    }
  }, [
    isPDFView,
    currentDoc,
    documentPathPrefix,
    inboxDocumentIds,
    parentPath,
    pdfBookmarks,
    leftSidebarHidden,
    tableOfContents,
  ]);

  const [documentLoaded, setDocumentLoaded] = useState(false);
  const [documentViewer, setDocumentViewer] = useState<Core.DocumentViewer>();
  const pdfTronContextValue = useMemo(
    () => ({ documentViewer, setDocumentViewer, documentLoaded, setDocumentLoaded }),
    [documentViewer, setDocumentViewer, documentLoaded, setDocumentLoaded],
  );

  const { headerIsHidden, setHeaderIsHidden, isBorderVisible } = useHeaderIsHidden({
    scrollableAncestorRef,
  });
  const [videoHeaderIsHidden, setVideoHeaderIsHidden] = useVideoHeaderIsHidden();
  const headerContextValue = useMemo(
    () => ({
      headerIsHidden: isYouTube ? videoHeaderIsHidden : headerIsHidden,
      setHeaderIsHidden: isYouTube ? setVideoHeaderIsHidden : setHeaderIsHidden,
      isBorderVisible,
    }),
    [
      headerIsHidden,
      setHeaderIsHidden,
      videoHeaderIsHidden,
      setVideoHeaderIsHidden,
      isYouTube,
      isBorderVisible,
    ],
  );

  const isFindInDocumentEnabled = docId && CSS.highlights;

  useEffect(() => {
    if (!isFindInDocumentEnabled) {
      clearFindInDocumentState('unknown');
    }
  }, [isFindInDocumentEnabled]);

  return (
    <AdaptiveHeaderContext.Provider value={headerContextValue}>
      <PdfTronContext.Provider value={pdfTronContextValue}>
        <div className={styles.root}>
          {docId && aside}
          <div className={`${styles.main} ${leftSidebarHidden ? styles.sidebarsHidden : ''}`}>
            {docId && currentDoc && isFirstClassDocument(currentDoc) &&
              <AdaptiveHeader
                doc={currentDoc}
                parentPath={parentPath}
                documentPathPrefix={documentPathPrefix}
                listedDocumentIds={inboxDocumentIds}
              /> ||
              null}
            {!isPDFView && isFindInDocumentEnabled && <FindInDocumentBar />}

            {!isPDFView && docId &&
              <DocumentContent
                docId={docId}
                currentDoc={currentDoc}
                isFetchingDoc={isFetchingDoc}
                onRendered={onRendered}
                wordCount={getWordCount()}
                scrollableAncestorRef={scrollableAncestorRef}
                onNewFocusTarget={onNewFocusTarget}
              /> ||
              null}

            <PDFViewer
              docId={docId}
              isPDF={isPDFView}
              currentDoc={currentDoc as DocumentWithTransientData<Pdf>}
              setPdfBookmarks={setPdfBookmarks}
              customPage={customPDFPage}
              scrollableAncestorRef={scrollableAncestorRef}
              parsedDocId={getParsedDocId()}
              readingPosition={currentDoc?.readingPosition}
              currentScrollPosition={currentDoc?.currentScrollPosition}
            />
          </div>
        </div>
      </PdfTronContext.Provider>
    </AdaptiveHeaderContext.Provider>
  );
}
