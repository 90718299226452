import { useEffect, useState } from 'react';

import { portalGate as portalGateToContentFrame } from './portalGates/contentFrame/to/reactNativeWebview';

export function useHasDocumentContentInitialized(): boolean {
  const [hasInitializedContent, setHasInitializedContent] = useState(false);
  useEffect(() => {
    const onContentInitialized = () => setHasInitializedContent(true);
    portalGateToContentFrame.once('chunk-content-initialized', onContentInitialized);
    portalGateToContentFrame.methods.emitEventWhenChunkedContentInitialized();
    return () => portalGateToContentFrame.off('chunk-content-initialized', onContentInitialized);
  }, []);
  return hasInitializedContent;
}
