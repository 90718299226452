import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MangoQuery } from 'rxdb';

import { convertQueryToRxDBQuery } from '../../filters-compiler/convertQueryToRxDBQuery';
// eslint-disable-next-line import/no-cycle
import type {
  AnyDocument,
  BaseDocument,
  ClientState,
  DocumentWithTransientData,
  FeedsStats,
  FilteredView,
  FirstClassDocument,
  Highlight,
  KeyOfDocumentWithTransientData,
  PartialDocument,
  PersistentState,
  RssFeed,
  TransientDocumentData,
} from '../../types';
// eslint-disable-next-line import/no-cycle
import {
  Category,
  FeedDocumentLocation,
  SidebarContentType,
  SortOrder,
  SortRule,
  SplitByKey,
} from '../../types';
import { DatabaseHookResultArray, DatabaseHookResultObject } from '../../types/database';
import { KeyboardLayout } from '../../types/keyboardShortcuts';
import type { DocumentTag, GlobalTagsObject } from '../../types/tags';
import { isArticle, isFirstClassDocument } from '../../typeValidators';
import {
  allDefaultCategoriesQueries,
  articlesQueries,
  emailsQueries,
  epubsQueries,
  pdfsQueries,
  tweetsQueries,
  videosQueries,
} from '../../utils/filteredViews';
import getUrlDomain from '../../utils/getUrlDomain';
import getWords from '../../utils/getWords';
import makeLogger from '../../utils/makeLogger';
import { narrowScreenWidth } from '../../utils/narrowScreenWidth';
// eslint-disable-next-line import/no-cycle
import ttsController from '../actions/ttsController.platform';
import createInitialTransientDocumentData from '../createInitialTransientDocumentData';
// eslint-disable-next-line import/no-cycle
import database from '../database';
import { useFindAll, useFindOne, useFindOnePartial } from '../databaseHooks';
import { fetchRelatedRSS } from '../methods';
// eslint-disable-next-line import/no-cycle
import { DEFAULT_SORT_RULES, globalState, isStaffProfile } from '../models';
import background from '../portalGates/toBackground'; // eslint-disable-line import/no-cycle
import { getCurrentSortRule } from '../stateGetters'; // eslint-disable-line import/no-cycle
import { fetchDocumentContent } from '../stateUpdaters/transientStateUpdaters/documentContent';
import getLastUpdatedMangoQueryFromView from '../utils/getLastUpdatedMangoQueryFromView';
import useDocumentLocations from '../utils/useDocumentLocations';
// eslint-disable-next-line import/no-cycle
import useGlobalStateWithFallback from '../utils/useGlobalStateWithFallback';
import { useFilteredViews } from './filteredViews';

const logger = makeLogger(__filename);

export const useDocumentContentFromState = (
  docId?: string,
): {
  content: BaseDocument['content'] | null | undefined;
  isFetching: boolean;
} => {
  const [currentDoc, { isFetching }] = usePartialDocument(
    docId,
    ['category', 'html', 'content', 'url', 'parsed_doc_id', 'transientData'],
    { shouldPollStateIfMissing: true },
  );

  const contentAndStatus = useMemo(() => {
    if (currentDoc) {
      return {
        content:
          currentDoc.category === Category.Highlight
            ? currentDoc.html
            : currentDoc.transientData.content,
        isFetching: false,
      };
    }
    return { isFetching, content: null };
  }, [currentDoc, isFetching]);

  const hasDoc = Boolean(currentDoc);
  const parsedDocId = currentDoc && isFirstClassDocument(currentDoc) && currentDoc?.parsed_doc_id;
  const articleDomain = currentDoc && isArticle(currentDoc) && getUrlDomain(currentDoc.url);

  useEffect(() => {
    if (!docId || !hasDoc || Boolean(contentAndStatus.content)) {
      return;
    }

    /*
      This is async. It isn't guaranteed the content will be there by the time this function returns.
      This is here to ensure the content is requested whenever we're trying to use it. It's called
      elsewhere too (to pre/load the content) but let's be safe. Even if the other calls are
      accidentally broken / removed, this will save us.
    */
    fetchDocumentContent([docId]);
    if (articleDomain) {
      fetchRelatedRSS([articleDomain]);
    }
  }, [docId, hasDoc, parsedDocId, articleDomain, contentAndStatus.content]);

  return contentAndStatus;
};

export const useCurrentSortRule = (
  args: Omit<Parameters<typeof getCurrentSortRule>[0], 'state'>,
): SortRule =>
  globalState(
    useCallback(
      (state) =>
        getCurrentSortRule({
          ...args,
          state,
        }),
      [args],
    ),
  );
export const useSortRules = ({
  filteredView,
  listId,
}: {
  filteredView?: FilteredView;
  listId?: string;
}): SortRule[] => {
  const documentListSortRules = globalState(
    useCallback(
      (state) => {
        if (!listId) {
          return;
        }
        return state.client.listSortRules[listId];
      },
      [listId],
    ),
  );

  const savedFilteredViewRules = filteredView?.sortRules;
  return savedFilteredViewRules ?? documentListSortRules ?? DEFAULT_SORT_RULES;
};

export const useDocument = <T extends AnyDocument = AnyDocument>(
  documentId: T['id'] | null | void,
  options?: {
    shouldPollStateIfMissing?: boolean;
  },
): [
  DocumentWithTransientData<T> | null,
  DatabaseHookResultObject<DocumentWithTransientData<T> | null>,
] => {
  const resultFromDatabase = useFindOne<'documents', T>('documents', documentId ?? undefined, {
    isEnabled: Boolean(documentId),
  });

  const [doc, databaseQueryResultObject] = resultFromDatabase;
  useEffect(() => {
    if (!databaseQueryResultObject.isFetching && !doc && options?.shouldPollStateIfMissing) {
      background.pollLatestState(1);
    }
  }, [databaseQueryResultObject.isFetching, doc, options?.shouldPollStateIfMissing]);

  const transientData = useGlobalStateWithFallback<TransientDocumentData>(
    createInitialTransientDocumentData(),
    useCallback(
      (state) => {
        if (!documentId) {
          return;
        }
        return state.transientDocumentsData[documentId];
      },
      [documentId],
    ),
  );

  const result = useMemo(() => {
    if (!doc) {
      return [null, databaseQueryResultObject] as [null, DatabaseHookResultObject<null>];
    }
    const documentWithTransientData = {
      ...doc,
      transientData,
    };
    return [
      documentWithTransientData,
      {
        ...databaseQueryResultObject,
        data: documentWithTransientData,
      },
    ] as [DocumentWithTransientData<T>, DatabaseHookResultObject<DocumentWithTransientData<T>>];
  }, [databaseQueryResultObject, doc, transientData]);

  return result;
};

/*
  1. Get the RxDocument (or null) from RxDB. We do not parse / convert to a regular JSON object.
  2. Pick out the desired keys.
  3. Get the transientData from Zustand (or a default).
  4. If transientData is one of the desired keys, add it to the partial document from RxDB.
  5. Replace the RxDocument in the database hook result array with this new combined partial document and return it.
*/
export function usePartialDocument<
  T extends AnyDocument,
  OnlyKey extends KeyOfDocumentWithTransientData<T>,
>(
  documentId: T['id'] | null | void,
  onlyKeys: OnlyKey[],
  options?: {
    shouldPollStateIfMissing?: boolean;
  },
): DatabaseHookResultArray<PartialDocument<T, OnlyKey> | null> {
  const onlyKeysIdentifier = onlyKeys.join(',');
  const keysToPick = useMemo(
    () => onlyKeys,
    // useMemo() compares arrays by identity, so onlyKeys could be different arrays with the same contents.
    // To prevent re-renders and render loops, we depend on a string representation of onlyKeys instead,
    // which will be equal for arrays with equal contents.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onlyKeysIdentifier],
  );
  const databaseResults = useFindOnePartial<'documents', T, OnlyKey>(
    'documents',
    documentId ?? undefined,
    {
      keysToPick,
      isEnabled: Boolean(documentId),
      ...options,
    },
  );

  const documentFromDatabase = useMemo(() => databaseResults[0], [databaseResults]);

  const transientData = useGlobalStateWithFallback<TransientDocumentData>(
    createInitialTransientDocumentData(),
    useCallback(
      (state) => {
        if (!documentId) {
          return;
        }
        return state.transientDocumentsData[documentId];
      },
      [documentId],
    ),
  );

  const partialDocument: PartialDocument<T, OnlyKey> | null = useMemo(() => {
    if (!documentFromDatabase) {
      return null;
    }
    if (!keysToPick.includes('transientData' as OnlyKey)) {
      return documentFromDatabase as PartialDocument<T, OnlyKey>;
    }
    return {
      ...documentFromDatabase,
      transientData,
    } as unknown as PartialDocument<T, OnlyKey>;
  }, [documentFromDatabase, keysToPick, transientData]);

  const databaseResultObjectWithoutData = useMemo(
    () => omit(databaseResults[1], 'data'),
    [databaseResults],
  );

  const [result, setResult] = useState<DatabaseHookResultArray<PartialDocument<T, OnlyKey> | null>>([
    null,
    {
      ...databaseResultObjectWithoutData,
      data: null,
    },
  ]);

  useEffect(() => {
    const newResultObject = {
      ...databaseResultObjectWithoutData,
      data: partialDocument,
    };

    if (isEqual(result, [partialDocument, newResultObject])) {
      return;
    }

    setResult([partialDocument, newResultObject]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [databaseResultObjectWithoutData, partialDocument]);

  return result;
}

export function usePartialFirstClassDocument<
  OnlyKey extends KeyOfDocumentWithTransientData<FirstClassDocument>,
>(
  documentId: FirstClassDocument['id'] | null | void,
  onlyKeys: OnlyKey[],
  options?: {
    shouldPollStateIfMissing?: boolean;
  },
): DatabaseHookResultArray<PartialDocument<FirstClassDocument, OnlyKey> | null> {
  return usePartialDocument<FirstClassDocument, OnlyKey>(documentId, onlyKeys, options);
}

export function usePartialHighlight<OnlyKey extends KeyOfDocumentWithTransientData<Highlight>>(
  documentId: Highlight['id'] | null | void,
  onlyKeys: OnlyKey[],
  options?: {
    shouldPollStateIfMissing?: boolean;
  },
): DatabaseHookResultArray<PartialDocument<Highlight, OnlyKey> | null> {
  return usePartialDocument<Highlight, OnlyKey>(documentId, onlyKeys, options);
}

export const useIsPDFViewAsHTML = (documentId: FirstClassDocument['id'] | null | undefined) => {
  const [doc] = usePartialDocument(documentId, ['source_specific_data']);
  return doc?.source_specific_data?.pdf?.viewAsHtml ?? false;
};

export const useIsEmailOriginalView = (documentId: FirstClassDocument['id'] | null | undefined) => {
  const [doc] = usePartialDocument(documentId, ['source_specific_data']);
  return doc?.source_specific_data?.email?.originalEmailView ?? false;
};

export const useAreEpubOriginalStylesEnabled = (
  doc: PartialDocument<FirstClassDocument, 'source_specific_data'> | null,
) => {
  return doc?.source_specific_data?.epub?.originalStylesEnabled;
};

export const useDocumentTagsAsObject = (
  documentId: FirstClassDocument['id'] | null,
): DatabaseHookResultArray<NonNullable<AnyDocument['tags']>> => {
  const [, resultObject] = usePartialDocument(documentId, ['tags']);
  return useMemo(() => {
    const data = resultObject.data?.tags ?? {};
    return [
      data,
      {
        ...resultObject,
        data,
      },
    ];
  }, [resultObject]);
};

export const useDocumentTagsAsArray = (
  documentId: FirstClassDocument['id'] | null,
): DatabaseHookResultArray<DocumentTag[]> => {
  const [, resultObject] = useDocumentTagsAsObject(documentId);
  return useMemo(() => {
    const data = Object.values(resultObject.data);
    return [
      data,
      {
        ...resultObject,
        data,
      },
    ];
  }, [resultObject]);
};

export function isNarrowScreenSize(width: number): boolean {
  return width < narrowScreenWidth;
}

// Temporary fix for duplicated data and null URLs
// https://linear.app/readwise/issue/RW-4852/inconsistencies-in-rss-sources-data
export const useRssFeeds = () => {
  const rssFeeds = globalState(useCallback((state) => state.persistent.rssFeeds, []));

  return useMemo((): NonNullable<PersistentState['rssFeeds']> => {
    if (!rssFeeds) {
      return {};
    }

    const urls: string[] = [];

    return Object.keys(rssFeeds).reduce((acc: { [key: string]: RssFeed; }, id: string) => {
      const url = rssFeeds[id].url;

      // Filter null values
      if (!url) {
        const name = rssFeeds[id].name;

        return {
          ...acc,
          [id]: {
            ...rssFeeds[id],
            url: `${name} (${id})`,
          },
        };
      }

      const isDuplicated = urls.includes(url);
      urls.push(url);

      if (isDuplicated) {
        return {
          ...acc,
          [id]: {
            ...rssFeeds[id],
            url: `${url} (${id})`,
          },
        };
      }

      return {
        ...acc,
        [id]: rssFeeds[id],
      };
    }, {});
  }, [rssFeeds]);
};

export const useClientDocumentSettings = (docId: AnyDocument['id']) => {
  return globalState(useCallback((state) => state.client.documents[docId], [docId]));
};

export const usePersistentPdfSettings = (docId: AnyDocument['id']) => {
  const [partialDoc] = usePartialDocument(docId, ['source_specific_data']);
  return useMemo(() => {
    const settings = partialDoc?.source_specific_data?.pdf ?? {};
    return {
      ...settings ?? {},
      sidebarContentType: settings?.sidebarContentType ?? SidebarContentType.Thumbnails,
      zoom: settings?.zoom ?? 1,
      rotation: settings?.rotation ?? 0,
    };
  }, [partialDoc]);
};

export const useCurrentTTSLanguageForDoc = (docId?: FirstClassDocument['id']) => {
  const [doc] = useFindOne<'documents', FirstClassDocument>('documents', docId);
  return ttsController.getCurrentTTSLanguageForDoc(doc);
};
export const useCurrentTTSVoiceForDoc = (docId?: FirstClassDocument['id']) => {
  const [doc] = useFindOne<'documents', FirstClassDocument>('documents', docId);
  return ttsController.getVoiceForDocument(doc);
};

export const useTTSWordBoundariesForVoice = (docId: AnyDocument['id'] | undefined) => {
  const voiceWordBoundaries = globalState(
    useCallback((state) => docId && state.transientDocumentsData[docId]?.tts, [docId]),
  );

  const voice = useCurrentTTSVoiceForDoc(docId);

  return ttsController.getTTSWordBoundariesForVoice(voiceWordBoundaries, voice);
};

export const useDocumentListSortRules = (listId: string) => {
  return useGlobalStateWithFallback(
    [],
    useCallback((state) => state.client.listSortRules[listId], [listId]),
  );
};

const desireViewsIdsOrder = [
  ...articlesQueries,
  ...epubsQueries,
  ...emailsQueries,
  ...pdfsQueries,
  ...tweetsQueries,
  ...videosQueries,
];

export const sortViews = (a: FilteredView, b: FilteredView) => {
  if (typeof a.order === 'number' && typeof b.order === 'number') {
    return a.order > b.order ? 1 : -1;
  }

  const index1 = desireViewsIdsOrder.indexOf(a.query);
  const index2 = desireViewsIdsOrder.indexOf(b.query);
  return (index1 > -1 ? index1 : Infinity) - (index2 > -1 ? index2 : Infinity);
};

export const useSavedFilteredViews = ({ excludeCategoryViews = false } = {}): FilteredView[] => {
  const stateViews = useFilteredViews();

  return useMemo(() => {
    const views = Object.keys(stateViews ?? {}).map((id) => stateViews[id]);

    const { pinned, notPinned } = views.slice().reduce(
      (
        acc: {
          pinned: FilteredView[];
          notPinned: FilteredView[];
        },
        view: FilteredView,
      ) => {
        if (excludeCategoryViews && allDefaultCategoriesQueries.includes(view.query)) {
          return acc;
        }

        if (view.isUnpinned) {
          return {
            ...acc,
            notPinned: [...acc.notPinned, view],
          };
        }

        return {
          ...acc,
          pinned: [...acc.pinned, view],
        };
      },
      { pinned: [], notPinned: [] },
    );

    return pinned.sort(sortViews).concat(notPinned);
  }, [stateViews, excludeCategoryViews]);
};

export const useSavedFilteredViewById = (id: string | undefined): FilteredView | undefined => {
  const filteredView = globalState(
    useCallback((state) => id ? state.persistent.filteredViews?.[id] : undefined, [id]),
  );

  return useMemo(() => {
    return filteredView;
  }, [filteredView]);
};

export const useRssSourceNameForDoc = (
  doc?: AnyDocument | PartialDocument<AnyDocument, 'source_specific_data'> | null | void,
) => {
  return globalState(
    useCallback(
      (state) => {
        const feedId = doc?.source_specific_data?.rss_feed;

        if (feedId && state.persistent.rssFeeds && state.persistent.rssFeeds[feedId]) {
          return state.persistent.rssFeeds[feedId].name;
        }
        return undefined;
      },
      [doc],
    ),
  );
};

export const useRssSourceNameFromPartialDoc = (
  doc?: PartialDocument<AnyDocument, 'source_specific_data'> | null | void,
) => {
  return globalState(
    useCallback(
      (state) => {
        const feedId = doc?.source_specific_data?.rss_feed;

        if (feedId && state.persistent.rssFeeds && state.persistent.rssFeeds[feedId]) {
          return state.persistent.rssFeeds[feedId].name;
        }
        return undefined;
      },
      [doc],
    ),
  );
};

export const useFaviconUrlFromDoc = (
  doc?: AnyDocument | PartialDocument<AnyDocument, 'source_specific_data' | 'favicon_url'> | null | void,
) => {
  return globalState(
    useCallback(
      (state) => {
        const feedId = doc?.source_specific_data?.rss_feed;

        if (feedId && state.persistent.rssFeeds && state.persistent.rssFeeds[feedId]?.image_url) {
          return state.persistent.rssFeeds[feedId].image_url;
        }

        return doc?.favicon_url;
      },
      [doc],
    ),
  );
};

export const useFaviconUrlFromPartialDoc = (
  doc?: PartialDocument<AnyDocument, 'favicon_url' | 'source_specific_data'> | null | void,
) => {
  return globalState(
    useCallback(
      (state) => {
        const feedId = doc?.source_specific_data?.rss_feed;

        if (feedId && state.persistent.rssFeeds && state.persistent.rssFeeds[feedId]?.image_url) {
          return state.persistent.rssFeeds[feedId].image_url;
        }

        return doc?.favicon_url;
      },
      [doc],
    ),
  );
};

export const useFaviconUrlFromFeedId = (feedId: string) => {
  return globalState(
    useCallback(
      (state) => {
        return state.persistent.rssFeeds?.[feedId]?.image_url;
      },
      [feedId],
    ),
  );
};

export const useNameFromFeedId = (feedId: string) => {
  return globalState(
    useCallback(
      (state) => {
        return state.persistent.rssFeeds?.[feedId]?.name;
      },
      [feedId],
    ),
  );
};

export const useFeedsStats = ({ isEnabled = true }: { isEnabled?: boolean; } = {}) => {
  const isLoading = useRef(false);
  const [hasFinishedCounts, setHasFinishedCounts] = useState(false);
  const [stats, setStats] = useState<FeedsStats>({});
  const rssFeeds = useGlobalStateWithFallback(
    {},
    useCallback((state) => state.persistent.rssFeeds, []),
  );
  const rssFeedsWithIds = useMemo(
    () =>
      Object.keys(rssFeeds).map((feedId) => {
        return {
          ...rssFeeds[feedId],
          id: feedId,
        };
      }),
    [rssFeeds],
  );

  const orderedRss = useMemo(() => {
    return orderBy(
      rssFeedsWithIds,
      [
        (feed) => {
          return feed.last_updated ?? 0;
        },
      ],
      [SortOrder.Desc],
    );
  }, [rssFeedsWithIds]);

  const rssKeys = useMemo(() => orderedRss.map((feed) => feed.id), [orderedRss]);

  useEffect(() => {
    if (isLoading.current || !isEnabled) {
      return;
    }

    isLoading.current = true;
    const countPromisesMethods: (() => Promise<number>)[] = [];

    rssKeys.forEach((feedId) => {
      const defaultResult = 0;

      const { mangoQuery } = convertQueryToRxDBQuery({
        query: `rssSource:"${feedId}"`,
        splitBy: SplitByKey.Seen,
        splitByValue: FeedDocumentLocation.New,
      });

      if (mangoQuery) {
        const queryPromiseMethod = () => {
          return database.collections.documents.count(mangoQuery).catch(() => {
            logger.error(`Error getting count result for feedId: ${feedId}`);
            return defaultResult;
          });
        };

        countPromisesMethods.push(queryPromiseMethod);
      } else {
        logger.error(`Error getting RxDB query for feedId: ${feedId}`);
        countPromisesMethods.push(() => Promise.resolve(defaultResult));
      }
    });

    async function fetchData() {
      for (const feedId of rssKeys) {
        const index = rssKeys.indexOf(feedId);
        const docsCountPromise = countPromisesMethods[index];
        const docsCount = await docsCountPromise();

        setStats((prev) => {
          return {
            ...prev,
            [feedId]: {
              docsCount,
            },
          };
        });
      }

      setHasFinishedCounts(true);
    }

    fetchData();
  }, [rssKeys, isEnabled]);

  const feedsWithStats = useMemo(() => {
    return rssKeys.map((rssKey) => {
      const feed = rssFeeds[rssKey];
      const feedStats = stats[rssKey];
      return {
        id: rssKey,
        ...feed,
        ...feedStats,
      };
    });
  }, [rssFeeds, rssKeys, stats]);

  return {
    feedsWithStats,
    hasFinishedCounts,
  };
};

export const useViewsByTagId = () => {
  const filteredViews = useFilteredViews();
  const [globalTagsObject] = useGlobalTagsAsObject();

  return useMemo(() => {
    return Object.keys(filteredViews).reduce(
      (acc, viewId) => {
        const tagsInQuery = Object.keys(globalTagsObject).filter((tagName) => {
          const possibleQueries = [`tag:"${tagName}"`, `tag:${tagName}`];

          const view = filteredViews[viewId];
          const query = view.query;
          return possibleQueries.some((possibleQuery) =>
            query.toLocaleLowerCase().includes(possibleQuery));
        });

        if (tagsInQuery.length) {
          for (const _tagName of tagsInQuery) {
            acc[_tagName] = [...acc[_tagName] || [], filteredViews[viewId]];
          }
        }

        return acc;
      },
      {} as { [tagId: string]: FilteredView[]; },
    );
  }, [filteredViews, globalTagsObject]);
};

export const useProfileName = () => {
  const profile = globalState(useCallback((state) => state.client.profile, []));

  return useMemo(() => {
    if (!profile?.first_name) {
      return null;
    }

    if (profile.first_name && profile.last_name) {
      return `${profile.first_name} ${profile.last_name}`;
    }

    return profile.first_name;
  }, [profile?.first_name, profile?.last_name]);
};

export const useProfileNameInitials = () => {
  const profile = globalState(useCallback((state) => state.client.profile, []));

  return useMemo(() => {
    if (!profile?.first_name) {
      return null;
    }

    if (profile.first_name && profile.last_name) {
      return `${profile.first_name[0]}${profile.last_name[0]}`.toLocaleUpperCase();
    }

    const nameParts = getWords(profile.first_name, 'unknown').slice(0, 2);
    return nameParts
      .map((part) => part[0])
      .join('')
      .toLocaleUpperCase();
  }, [profile?.first_name, profile?.last_name]);
};

export const useKeyboardLayout = () => {
  return useGlobalStateWithFallback(
    KeyboardLayout.QwertyUS,
    useCallback((state) => state.persistent.keyboardLayout, []),
  );
};

export const useFilteredViewsStats = ({
  computeLastUpdated = true,
  excludeCategoryViews = false,
} = {}) => {
  const isLoading = useRef(false);
  const [hasFinishedLastUpdate, setHasFinishedLastUpdate] = useState(false);
  const [hasFinishedCounts, setHasFinishedCounts] = useState(false);
  const [stats, setStats] = useState<{ [key: string]: { count: number; lastUpdate: number | null; }; }[]>(
    [],
  );
  const documentLocations = useDocumentLocations();
  const stateViewsObject = useFilteredViews();
  const allStateViews = Object.values(stateViewsObject);
  const filteredViews = useMemo(() => {
    if (!excludeCategoryViews) {
      return allStateViews;
    }

    return allStateViews.filter((view) => !allDefaultCategoriesQueries.includes(view.query));
  }, [allStateViews, excludeCategoryViews]);
  const viewIds = useMemo(() => Object.keys(filteredViews), [filteredViews]);

  useEffect(() => {
    if (isLoading.current) {
      return;
    }
    isLoading.current = true;
    const countPromisesMethods: (() => Promise<number | undefined>)[] = [];
    const lastUpdatedDocPromisesMethods: (() => Promise<AnyDocument | null>)[] = [];

    viewIds.forEach((viewId) => {
      const view = filteredViews[viewId];
      const mangoQuery = getLastUpdatedMangoQueryFromView(view, documentLocations);

      if (mangoQuery) {
        const countQueryPromiseMethod = () =>
          database.collections.documents.count(mangoQuery).catch(() => {
            logger.error(`Error getting count result for viewId: ${viewId}`);
            return undefined;
          });
        countPromisesMethods.push(countQueryPromiseMethod);

        if (computeLastUpdated) {
          const lastUpdatedQueryPromiseMethod = () =>
            database.collections.documents.findOne(mangoQuery).catch(() => {
              logger.error(`Error getting first doc for viewId: ${viewId}`);
              return null;
            });

          lastUpdatedDocPromisesMethods.push(lastUpdatedQueryPromiseMethod);
        }
      } else {
        logger.error(`Error getting RxDB query for viewId: ${viewId}`);
        countPromisesMethods.push(() => Promise.resolve(0));
        lastUpdatedDocPromisesMethods.push(() => Promise.resolve(null));
      }
    });

    async function fetchData() {
      for (const viewId of viewIds) {
        const index = viewIds.indexOf(viewId);
        const countPromiseMethod = countPromisesMethods[index];
        const count = await countPromiseMethod();

        setStats((prev) => {
          return {
            ...prev,
            [viewId]: {
              count,
            },
          };
        });
      }

      setHasFinishedCounts(true);

      if (computeLastUpdated) {
        for (const viewId of viewIds) {
          const index = viewIds.indexOf(viewId);
          const lastUpdatedDocPromiseMethod = lastUpdatedDocPromisesMethods[index];
          const lastUpdatedDoc = await lastUpdatedDocPromiseMethod();

          setStats((prev) => {
            return {
              ...prev,
              [viewId]: {
                ...prev[viewId] || {},
                lastUpdate: lastUpdatedDoc?.last_status_update,
              },
            };
          });
        }

        setHasFinishedLastUpdate(true);
      }
    }

    fetchData();
  }, [documentLocations, filteredViews, viewIds, computeLastUpdated]);

  const viewsWithStats = useMemo(() => {
    return viewIds.map((viewId) => {
      const view = filteredViews[viewId];
      const viewStats = stats[viewId];
      return {
        ...view,
        ...viewStats,
      } as FilteredView & { count: number; lastUpdate: number | null; };
    });
  }, [filteredViews, viewIds, stats]);

  return {
    viewsWithStats,
    hasFinishedLastUpdate,
    hasFinishedCounts,
  };
};

export const useGlobalTagsAsObject = (): DatabaseHookResultArray<GlobalTagsObject> => {
  const [, result] = useGlobalTagsAsArray();

  return useMemo(() => {
    const globalTagsObject = keyBy(result.data, 'id');
    return [
      globalTagsObject,
      {
        ...result,
        data: globalTagsObject,
      },
    ];
  }, [result]);
};

export const useGlobalTagsAsArray = () => useFindAll('global_tags');

export function useFocusedDocumentListQuery(): MangoQuery<AnyDocument> | null {
  return globalState(useCallback((state) => state.focusedDocumentListQuery, []));
}

export function useIsMobileCPUThrottled() {
  return globalState(useCallback((state) => state.isMobileCPUThrottled, []));
}

export function useIsMobileAppReviewDebugging() {
  return globalState(useCallback((state) => state.isMobileAppReviewDebugging, []));
}

export function useMobileDeveloperSetting<T extends keyof ClientState['mobileDeveloperSettings']>(
  settingName: T,
): ClientState['mobileDeveloperSettings'][T] {
  return globalState(
    useCallback(
      (state) => {
        const settingValue = state.client.mobileDeveloperSettings[settingName];
        const isStaff = isStaffProfile(state);
        return isStaff && settingValue;
      },
      [settingName],
    ),
  );
}
