import { useMemo } from 'react';
import { Category, DocumentLocation, FeedDocumentLocation, SidebarCategory } from 'shared/types';
import { categoryQueryMap } from 'shared/utils/filteredViews';

import { getFilterViewQueryFromPathname } from '../utils/pathnameHelpers';
import useLocation from '../utils/useLocation';

/**
 * Returns the current SidebarCategory based on the pathname.
 *
 * The sidebar categories include regular top level document categories, as well as the library.
 */
export const useCurrentSidebarCategory = () => {
  const location = useLocation();
  const { pathname } = location;
  const currentQuery = useMemo(
    () => getFilterViewQueryFromPathname(pathname) || '',
    [pathname],
  );

  const currentCategory: SidebarCategory | undefined = useMemo(() => {
    const isFeed = Boolean(
      pathname.match(
        `/${DocumentLocation.Feed}/(${Object.values(FeedDocumentLocation).join(
          '|',
        )})`,
      ),
    );

    if (isFeed) {
      return Category.RSS;
    }

    const categoryKey = Object.entries(categoryQueryMap).find(
      ([_, queries]) => {
        return queries.some((query) => currentQuery.includes(query));
      },
    )?.[0] as SidebarCategory | undefined;

    if (!categoryKey) {
      return 'library';
    }

    return categoryKey;
  }, [currentQuery, pathname]);

  return currentCategory;
};
