import { useEffect, useState } from 'react';

import { DeferredPromise } from '../utils/DeferredPromise';
import { portalGate as portalGateToContentFrame } from './portalGates/contentFrame/to/reactNativeWebview';

export async function waitUntilDocumentContentAtPositionLoaded(serializedPosition: string): Promise<void> {
  const eventToken = Math.random().toString().slice(2);
  const promise = new DeferredPromise<void>();
  const onChunkContentAtPositionLoad = (token: string) => {
    if (token !== eventToken) {
      return;
    }
    promise.resolve();
    portalGateToContentFrame.off('chunk-content-at-position-loaded', onChunkContentAtPositionLoad);
  };
  portalGateToContentFrame.on('chunk-content-at-position-loaded', onChunkContentAtPositionLoad);
  portalGateToContentFrame.methods.emitEventWhenChunkContentAtPositionLoads(serializedPosition, eventToken);
  return promise;
}

export function useHasLoadedDocumentContentAtPosition(serializedPosition: string | null | undefined): boolean {
  const [hasLoadedContent, setHasLoadedContent] = useState(false);

  useEffect(() => {
    if (!serializedPosition) {
      return;
    }
    const eventToken = Math.random().toString().slice(2);
    const onChunkContentAtPositionLoad = (token: string) => {
      if (token !== eventToken) {
        return;
      }
      setHasLoadedContent(true);
      portalGateToContentFrame.off('chunk-content-at-position-loaded', onChunkContentAtPositionLoad);
    };
    portalGateToContentFrame.on('chunk-content-at-position-loaded', onChunkContentAtPositionLoad);
    portalGateToContentFrame.methods.emitEventWhenChunkContentAtPositionLoads(serializedPosition, eventToken);

    return () => {
      portalGateToContentFrame.off('chunk-content-at-position-loaded', onChunkContentAtPositionLoad);
    };
  }, [serializedPosition]);

  return hasLoadedContent;
}

