import { parse } from 'date-fns';

// 0:05:14 => 5 mins and 14 secs
// 1:00:00 => 1 hour
export function getFormattedDurationFromTime(input: string): string {
  const time = parse(input, 'H:mm:ss', new Date(0));

  const hours = time.getHours();
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();

  let result = '';

  if (hours > 0) {
    result += `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
  }
  if (minutes > 0) {
    if (result) {
      result += ' and ';
    }
    result += `${minutes} ${minutes === 1 ? 'min' : 'mins'}`;
  }
  if (!result && seconds > 0) {
    result = `${seconds} ${seconds === 1 ? 'sec' : 'secs'}`;
  }

  return result;
}
