import omit from 'lodash/omit';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import { useCallback, useMemo } from 'react';
import type { RxCollection, RxDocument, RxQuery } from 'rxdb/dist/types/types';
import { MangoQuery } from 'rxdb/dist/types/types';

// eslint-disable-next-line restrict-imports/restrict-imports
import { Cache } from '../../background/cache.platform';
import getPrimaryKeyFromDatabaseEntry from '../../database/getPrimaryKeyFromDatabaseEntry';
import optimizeMangoQuery from '../../database/internals/optimizeMangoQuery';
import sortDatabaseItemsByIdList from '../../database/sortDatabaseItemsByIdList';
import { ConvertRxDocument } from '../../types';
import type {
  CommonDatabaseHookOptions,
  DatabaseCollection,
  DatabaseCollectionNames,
  DatabaseCollectionNamesToDocType,
  DatabaseHookResultArray,
  RxCollections,
} from '../../types/database';
// eslint-disable-next-line import/no-cycle
import database from '../database';
import { useDeepEqualMemo } from '../utils/useDeepEqualMemo';
import getRxCollection from './internals/getRxCollection';
import {
  useArrayQuerySubscription,
  useNumberQuerySubscription,
  useQuerySubscription,
  useSingleDatabaseResultQuerySubscription,
} from './internals/subscriptionHooks';
import useOptions from './internals/useOptions';

// Forward export of useFindInfinite
export * from './internals/useFindInfinite';

// https://linear.app/readwise/issue/RW-32333/database-hooks-even-when-isenabled-false-an-entry-is-put-in-rxdbs
function useRxQueryIfEnabled<
  TCollectionName extends DatabaseCollectionNames,
  TRxQuery extends RxQuery = RxQuery,
>({
  collectionName,
  isEnabled = true,
  queryCreator,
}: {
  collectionName: TCollectionName;
  isEnabled?: boolean;
  queryCreator: (rxCollection: RxCollections[TCollectionName]) => TRxQuery;
}): TRxQuery | undefined {
  return useMemo(() => {
    if (!isEnabled) {
      return;
    }
    return queryCreator(getRxCollection(database, collectionName));
  }, [collectionName, isEnabled, queryCreator]);
}

export function useCount<TCollectionName extends DatabaseCollectionNames>(
  collectionName: TCollectionName,
  query?: MangoQuery<DatabaseCollectionNamesToDocType[TCollectionName]>,
  optionsArgument: CommonDatabaseHookOptions = {},
) {
  const options = useOptions(optionsArgument, !query);

  const rxQuery = useRxQueryIfEnabled<
    TCollectionName,
    ReturnType<RxCollection<DatabaseCollectionNamesToDocType[TCollectionName]>['count']>
  >({
    collectionName,
    isEnabled: options.isEnabled,
    queryCreator: useCallback(
      (rxCollection) =>
        rxCollection.count(optimizeMangoQuery(rxCollection, omit(query, ['sort', 'limit', 'skip']))),
      [query],
    ),
  });
  return useNumberQuerySubscription<TCollectionName>({
    rxQuery,
    ...options,
  });
}

export function useCountAll<TCollectionName extends DatabaseCollectionNames>(
  collectionName: TCollectionName,
  optionsArgument: CommonDatabaseHookOptions = {},
) {
  const options = useOptions(optionsArgument);

  const rxQuery = useRxQueryIfEnabled<
    TCollectionName,
    ReturnType<RxCollection<DatabaseCollectionNamesToDocType[TCollectionName]>['count']>
  >({
    collectionName,
    isEnabled: options.isEnabled,
    queryCreator: useCallback((rxCollection) => rxCollection.count(), []),
  });
  return useNumberQuerySubscription<TCollectionName>({
    rxQuery,
    ...options,
  });
}

export function useFind<
  TCollectionName extends DatabaseCollectionNames = DatabaseCollectionNames,
  TReturnedItemType extends
    DatabaseCollectionNamesToDocType[TCollectionName] = DatabaseCollectionNamesToDocType[TCollectionName],
>(
  collectionName: TCollectionName,
  query?: MangoQuery<DatabaseCollectionNamesToDocType[TCollectionName]>,
  optionsArgument: {
    postProcessData?: (data: DatabaseCollectionNamesToDocType[TCollectionName][]) => TReturnedItemType[];
    limitBuffer?: number;
    enablePersistentQueryCache?: boolean;
    convertRxDocument?: ConvertRxDocument<
      DatabaseCollectionNamesToDocType[TCollectionName],
      TReturnedItemType
    >;
  } & CommonDatabaseHookOptions = {},
) {
  const options = useOptions(optionsArgument, !query);

  const rxQuery = useRxQueryIfEnabled<
    TCollectionName,
    RxQuery<
      DatabaseCollectionNamesToDocType[TCollectionName],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      RxDocument<DatabaseCollectionNamesToDocType[TCollectionName], any>
    >
  >({
    collectionName,
    isEnabled: options.isEnabled,
    queryCreator: useCallback(
      (rxCollection) => {
        let rxQuery = rxCollection.find(optimizeMangoQuery(rxCollection, query));

        if (query && optionsArgument.limitBuffer && query.limit && !query.skip) {
          rxQuery = rxQuery.enableLimitBuffer(optionsArgument.limitBuffer);
        }

        return rxQuery;
      },
      [query, optionsArgument.limitBuffer],
    ),
  });

  if (options.enablePersistentQueryCache) {
    rxQuery?.enablePersistentQueryCache(Cache);
  }

  return useArrayQuerySubscription<TCollectionName, TReturnedItemType[]>({
    rxQuery,
    ...options,
  });
}

export function useFindAll<
  TCollectionName extends DatabaseCollectionNames = DatabaseCollectionNames,
  TReturnedItemType extends
    DatabaseCollectionNamesToDocType[TCollectionName] = DatabaseCollectionNamesToDocType[TCollectionName],
>(
  collectionName: TCollectionName,
  optionsArgument: CommonDatabaseHookOptions & {
    convertRxDocument?: ConvertRxDocument<
      DatabaseCollectionNamesToDocType[TCollectionName],
      TReturnedItemType
    >;
  } = {},
) {
  const options = useOptions(optionsArgument);

  const rxQuery = useRxQueryIfEnabled<
    TCollectionName,
    RxQuery<
      DatabaseCollectionNamesToDocType[TCollectionName],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      RxDocument<DatabaseCollectionNamesToDocType[TCollectionName], any>
    >
  >({
    collectionName,
    isEnabled: options.isEnabled,
    queryCreator: useCallback((rxCollection) => rxCollection.find(), []),
  });
  return useArrayQuerySubscription<TCollectionName, TReturnedItemType[]>({
    rxQuery,
    ...options,
  });
}

export function useFindAllIds<TCollectionName extends DatabaseCollectionNames>(
  collectionName: TCollectionName,
  optionsArgument: CommonDatabaseHookOptions = {},
) {
  const options = useOptions(optionsArgument);
  const rxCollection = useMemo(
    () => getRxCollection<TCollectionName>(database, collectionName),
    [collectionName],
  );
  const postProcessData = useCallback(
    (data: DatabaseCollectionNamesToDocType[TCollectionName][]) => {
      return data.map((item) => getPrimaryKeyFromDatabaseEntry(item, rxCollection));
    },
    [rxCollection],
  );

  const rxQuery = useRxQueryIfEnabled<
    TCollectionName,
    ReturnType<RxCollection<DatabaseCollectionNamesToDocType[TCollectionName]>['find']>
  >({
    collectionName,
    isEnabled: options.isEnabled,
    queryCreator: useCallback((rxCollection) => rxCollection.find(), []),
  });

  return useArrayQuerySubscription<
    TCollectionName,
    DatabaseCollectionNamesToDocType[TCollectionName]['id'][]
  >({
    postProcessData,
    rxQuery,
    shouldSkipConversionFromRxDocuments: true,
    ...options,
  });
}

// This preserves the order of (ids -> items)
export function useFindByIds<
  TCollectionName extends DatabaseCollectionNames = DatabaseCollectionNames,
  TReturnedItemType extends
    DatabaseCollectionNamesToDocType[TCollectionName] = DatabaseCollectionNamesToDocType[TCollectionName],
>(
  collectionName: TCollectionName,
  ids?: string[],
  optionsArgument: CommonDatabaseHookOptions & {
    convertRxDocument?: ConvertRxDocument<DatabaseCollectionNamesToDocType[TCollectionName]>;
  } = {},
) {

  const options = useOptions(optionsArgument, !ids?.length);

  const uniqueIds = useMemo(() => uniq(ids), [ids]);
  const rxQuery = useRxQueryIfEnabled<
    TCollectionName,
    ReturnType<RxCollection<DatabaseCollectionNamesToDocType[TCollectionName]>['findByIds']>
  >({
    collectionName,
    isEnabled: options.isEnabled,
    queryCreator: useCallback((rxCollection) => rxCollection.findByIds(uniqueIds), [uniqueIds]),
  });

  const postProcessData = useCallback(
    (items) => {
      // At this points `ids` will exist FYI
      return sortDatabaseItemsByIdList<TReturnedItemType>({
        ids: ids ?? [],
        items,
        rxCollection: getRxCollection(database, collectionName),
      });
    },
    [collectionName, ids],
  );

  // `rxCollection.findByIds` returns a map, not an array, so we can't use `useArrayQuerySubscription`
  return useQuerySubscription<
    TCollectionName,
    TReturnedItemType[],
    DatabaseCollectionNamesToDocType[TCollectionName]
  >({
    initialDataValue: [],
    rxQuery,
    postProcessData,
    ...options,
  });
}

export function useFindOne<
  TCollectionName extends DatabaseCollectionNames = DatabaseCollectionNames,
  TReturnedItemType extends
    DatabaseCollectionNamesToDocType[TCollectionName] = DatabaseCollectionNamesToDocType[TCollectionName],
>(
  collectionName: TCollectionName,
  query?: string | MangoQuery<DatabaseCollectionNamesToDocType[TCollectionName]>,
  optionsArgument: {
    convertRxDocument?: ConvertRxDocument<
      DatabaseCollectionNamesToDocType[TCollectionName],
      TReturnedItemType
    >;
  } & CommonDatabaseHookOptions = {},
) {
  const options = useOptions(optionsArgument, !query);

  const postProcessData = useCallback(
    (data: DatabaseCollectionNamesToDocType[TCollectionName][]) =>
      (data as TReturnedItemType[]).find(Boolean) ?? null,
    [],
  );

  const rxQuery = useRxQueryIfEnabled<
    TCollectionName,
    ReturnType<RxCollection<DatabaseCollectionNamesToDocType[TCollectionName]>['findOne']>
  >({
    collectionName,
    isEnabled: options.isEnabled,
    queryCreator: useCallback(
      (rxCollection) =>
        rxCollection.findOne(
          typeof query === 'string'
            ? query
            : optimizeMangoQuery(rxCollection, omit(query, ['sort', 'limit', 'skip'])),
        ),
      [query],
    ),
  });
  return useSingleDatabaseResultQuerySubscription<TCollectionName, TReturnedItemType | null>({
    postProcessData,
    rxQuery,
    ...options,
  });
}

export function useFindOnePartial<
  TCollectionName extends DatabaseCollectionNames = DatabaseCollectionNames,
  TReturnedItemType extends
    DatabaseCollectionNamesToDocType[TCollectionName] = DatabaseCollectionNamesToDocType[TCollectionName],
  TKeyToPick extends keyof TReturnedItemType | 'transientData' =
    | keyof TReturnedItemType
    | 'transientData',
  TKeyToPickWithoutTransientData extends Exclude<TKeyToPick, 'transientData'> = Exclude<
    TKeyToPick,
    'transientData'
  >,
  TReturnedItemTypeFactoringInKeysToPick extends Pick<
    TReturnedItemType,
    TKeyToPickWithoutTransientData
  > = Pick<TReturnedItemType, TKeyToPickWithoutTransientData>,
>(
  collectionName: TCollectionName,
  query: Parameters<DatabaseCollection<TCollectionName>['findOne']>[0] | undefined,
  optionsArgument: { keysToPick: (TKeyToPick | 'transientData')[]; } & CommonDatabaseHookOptions,
): DatabaseHookResultArray<TReturnedItemTypeFactoringInKeysToPick | null> {
  const options = useOptions(optionsArgument, !query);

  const { keysToPick: keysToPickArg, ...otherOptions } = options;

  // Users of this hook may pass a new array per render; since react compares references without this
  // downstream hooks may re-render needlessly
  const keysToPick = useDeepEqualMemo(keysToPickArg);

  const postProcessData = useCallback(
    (data: DatabaseCollectionNamesToDocType[TCollectionName][]) =>
      (data as TReturnedItemType[]).find(Boolean) ?? null,
    [],
  );

  const rxQuery = useRxQueryIfEnabled<
    TCollectionName,
    ReturnType<RxCollection<DatabaseCollectionNamesToDocType[TCollectionName]>['findOne']>
  >({
    collectionName,
    isEnabled: options.isEnabled,
    queryCreator: useCallback(
      (rxCollection) =>
        rxCollection.findOne(
          typeof query === 'string' ? query : optimizeMangoQuery(rxCollection, query),
        ),
      [query],
    ),
  });
  const [rxDocument, queryResultObject] = useSingleDatabaseResultQuerySubscription<
    TCollectionName,
    TReturnedItemType | null
  >({
    postProcessData,
    rxQuery,
    ...otherOptions,
    keysToPick,
  }) as DatabaseHookResultArray<RxDocument<TReturnedItemType> | null>;

  const partialItem = useMemo(() => {
    if (!rxDocument) {
      return null;
    }

    // We can't use `rxDocument.get(keyName)` for example; see https://github.com/pubkey/rxdb/issues/4926
    return pick(rxDocument, keysToPick) as unknown as TReturnedItemTypeFactoringInKeysToPick;
  }, [rxDocument, keysToPick]);

  return useMemo(
    () => [
      partialItem,
      {
        ...queryResultObject,
        data: partialItem,
      },
    ],
    [partialItem, queryResultObject],
  );
}

export function useFindIds<TCollectionName extends DatabaseCollectionNames>(
  collectionName: TCollectionName,
  query?: MangoQuery<DatabaseCollectionNamesToDocType[TCollectionName]>,
  optionsArgument: CommonDatabaseHookOptions = {},
) {
  const options = useOptions(optionsArgument, !query);
  const rxCollection = useMemo(
    () => getRxCollection<TCollectionName>(database, collectionName),
    [collectionName],
  );
  const postProcessData = useCallback(
    (data: DatabaseCollectionNamesToDocType[TCollectionName][]) => {
      return data.map((item) => getPrimaryKeyFromDatabaseEntry(item, rxCollection));
    },
    [rxCollection],
  );

  const rxQuery = useRxQueryIfEnabled<
    TCollectionName,
    ReturnType<RxCollection<DatabaseCollectionNamesToDocType[TCollectionName]>['find']>
  >({
    collectionName,
    isEnabled: options.isEnabled,
    queryCreator: useCallback(
      (rxCollection) => rxCollection.find(optimizeMangoQuery(rxCollection, query)),
      [query],
    ),
  });

  return useArrayQuerySubscription<
    TCollectionName,
    DatabaseCollectionNamesToDocType[TCollectionName]['id'][]
  >({
    postProcessData,
    rxQuery,
    shouldSkipConversionFromRxDocuments: true,
    ...options,
  });
}
