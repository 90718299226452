import React, { useCallback, useEffect } from 'react';
import eventEmitter from 'shared/foreground/eventEmitter';
import { useFocusedDocumentId } from 'shared/foreground/stateHooks/useFocusedDocument';
import type { BaseDocument } from 'shared/types';

import getNumericCssPropertyValue from '../utils/getNumericCssPropertyValue';
import styles from './InboxFocusIndicator.module.css';

export const getSelectedRow = (focusedDocumentId: BaseDocument['id']): HTMLElement | null => {
  return document.querySelector(`#document-row-${focusedDocumentId}`) as HTMLElement | null;
};

export const position = (focusedDocumentId: BaseDocument['id'], list: HTMLElement): void => {
  // Scroll the dropdown component to the right location if the selected row is out of view
  // Update the hover bar after render
  const selectedRow = getSelectedRow(focusedDocumentId);
  const hoverBar = document.getElementById('#document-hover-bar');
  if (!hoverBar || !selectedRow) {
    return;
  }

  hoverBar.classList.add('animate-hover-bar');
  const top = selectedRow.getBoundingClientRect().top - list.getBoundingClientRect().top;
  if (top < 0) {
    list.scrollTop += top;
    hoverBar?.classList.remove('animate-hover-bar');
  } else if (top + selectedRow.clientHeight > list.clientHeight) {
    list.scrollTop += top + selectedRow.clientHeight - list.clientHeight;
    hoverBar?.classList.remove('animate-hover-bar');
  }
  hoverBar.style.height = `${selectedRow.clientHeight}px`;
  const docListPaddingTop = getNumericCssPropertyValue('--document-list-padding-top');
  hoverBar.style.transform = `translate(0, ${selectedRow.offsetTop + docListPaddingTop}px)`;
};

export default React.memo(function InboxFocusIndicator({
  scrollableRootRef,
}: { scrollableRootRef: React.RefObject<HTMLDivElement>; }): JSX.Element | null {
  const focusedDocumentId = useFocusedDocumentId();

  const focusIndicator = useCallback(() => {
    (async () => {
      if (!scrollableRootRef.current || !focusedDocumentId) {
        return;
      }

      position(focusedDocumentId, scrollableRootRef.current);
    })();
  }, [focusedDocumentId, scrollableRootRef]);

  /* This is all the CSS / DOM manipulation code after each re-render */
  useEffect(() => {
    focusIndicator();

    eventEmitter.on('refocus-inbox-focus-indicator', focusIndicator);

    return () => {
      eventEmitter.off('refocus-inbox-focus-indicator', focusIndicator);
    };
  }, [focusedDocumentId, scrollableRootRef, focusIndicator]);

  if (!focusedDocumentId) {
    return null;
  }

  return (
    <div className={styles.hoverContainer}>
      <div className={styles.hoverBar} id="#document-hover-bar" />
    </div>
  );
});
