import { useCallback, useMemo } from 'react';
import { convertQueryToRxDBQuery } from 'shared/filters-compiler/convertQueryToRxDBQuery';
import { useCount } from 'shared/foreground/databaseHooks';
import { globalState } from 'shared/foreground/models';
import {
  DocumentLocation,
  FeedDocumentLocation,
  SplitByKey,
} from 'shared/types';

import {
  getCurrentDocumentLocationFromPathname,
  getFeedDocumentLocationFromPathname,
  getFilterViewQueryFromPathname,
  getSplitByValueFromPathname,
} from '../utils/pathnameHelpers';
import useLocation from '../utils/useLocation';
import { useCurrentSidebarCategory } from './useCurrentCategory';

/**
 * Handles the logic for determining the empty state of document categories and the library.
 *
 * `isEmptyStateCategory` is true when the current category view is marked to show the empty state. This state might be useful to show UI for dismissing the emptyStateCategory.
 * `isInboxZero` is true when there are no documents in the current category view and the category is loaded.
 * `isEmptyState` is true when `isInboxZero` or `isEmptyStateCategory` is true.
 */
export const useIsInboxZero = () => {
  const location = useLocation();
  const { pathname } = location;

  const currentCategory = useCurrentSidebarCategory();
  const emptyStateCategory = globalState(
    useCallback((state) => state.emptyStateCategory, []),
  );

  // Returns the document location for the current category view
  const categoryDocumentLocation = getSplitByValueFromPathname(pathname) as
    | DocumentLocation
    | undefined;

  const feedDocumentLocation = getFeedDocumentLocationFromPathname(pathname) as
    | FeedDocumentLocation
    | undefined;

  // Returns the top-level document location for the library view
  const libraryDocumentLocation = getCurrentDocumentLocationFromPathname(
    pathname,
  ) as DocumentLocation | undefined;

  const currentQuery = useMemo(
    () => getFilterViewQueryFromPathname(pathname) || '',
    [pathname],
  );

  const isLibraryView = currentCategory === 'library';
  const isFeedView = currentCategory === 'rss';

  const query = isLibraryView
    ? '*'
    : isFeedView
      ? `triage_status:${DocumentLocation.Feed}`
      : currentQuery;

  const { mangoQuery } = useMemo(
    () =>
      convertQueryToRxDBQuery({
        query,
        documentLocation: isLibraryView
          ? libraryDocumentLocation
          : isFeedView
            ? undefined
            : categoryDocumentLocation,
        splitBy: isFeedView ? SplitByKey.Seen : SplitByKey.DocumentLocation,
        splitByValue: isLibraryView
          ? libraryDocumentLocation
          : isFeedView
            ? feedDocumentLocation
            : categoryDocumentLocation,
        feedDocumentLocation,
      }),
    [
      query,
      isLibraryView,
      isFeedView,
      categoryDocumentLocation,
      feedDocumentLocation,
      libraryDocumentLocation,
    ],
  );

  const [count, { isFetchingInitialInput }] = useCount('documents', mangoQuery, {
    isEnabled: Boolean(mangoQuery),
  });

  const isSearchScreen = pathname.startsWith('/search');

  const isInboxZero = !isFetchingInitialInput && !isSearchScreen && count === 0;

  const isEmptyStateCategory =
    currentCategory === 'library'
      ? Boolean(emptyStateCategory)
      : emptyStateCategory === currentCategory;

  const isEmptyState = isInboxZero || isEmptyStateCategory;

  return {
    isEmptyState,
    isInboxZero,
    isEmptyStateCategory,
  };
};
