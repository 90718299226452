import { useMemo } from 'react';
import combineClasses from 'shared/foreground/utils/combineClasses';

export function useDocumentTextContentClassName(
  contentClasses: (string | undefined)[],
  originalEmailView: undefined | boolean,
) {
  const mandatoryStylesClass =
    'mandatory-styles specific-1 specific-2 specific-3 specific-4 specific-5 specific-6 specific-7 specific-8 specific-9 specific-10';
  return useMemo(() => combineClasses(
    contentClasses,
    originalEmailView
      ? 'document-content--original-email'
      : ['document-content', mandatoryStylesClass],
  ), [contentClasses, originalEmailView]);
}
