import { AnyDocument } from '../../types';
import { SendToKindleResult } from '../../types/misc';
import getServerBaseUrl from '../../utils/getServerBaseUrl.platform';
import requestWithAuth from '../../utils/requestWithAuth.platformIncludingExtension';
import { setSendToKindleDocId } from '../stateUpdaters/transientStateUpdaters/other';
import { createToast } from '../toasts.platform';


export const sendToKindle = async (
  docId: AnyDocument['id'],
  force = false,
): Promise<SendToKindleResult> => {
  if (docId) {
    await setSendToKindleDocId(docId);
  }

  try {
    const resp = await requestWithAuth(`${getServerBaseUrl()}/reader/api/send_to_kindle/${docId}`, {
      credentials: 'include',
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({ force }),
    });

    if (!resp.ok) {
      throw new Error('Failed to send to Kindle');
    }

    const data = await resp.json();
    if (data.status === 'already_sent') {
      return { status: 'already_sent' };
    } else if (data.status === 'success') {
      await setSendToKindleDocId(null);
      createToast({
        content: 'Sent to Kindle successfully',
        category: 'success',
      });
      return { status: 'success' };
    }
    return { status: 'error' };
  } catch (error) {
    createToast({
      content: 'Failed to send to Kindle',
      category: 'error',
    });
    return { status: 'error' };
  }
};


export const sendDigestToKindle = async () => {
  const resp = await requestWithAuth(`${getServerBaseUrl()}/reader/api/send_kindle_digest/`, {
    credentials: 'include',
    method: 'POST',
    mode: 'cors',
  });
  if (!resp.ok) {
    createToast({
      content: 'Failed to send digest to Kindle',
      category: 'error',
    });
  } else {
    createToast({
      content: 'Sent digest to Kindle successfully',
      category: 'success',
    });
  }
};
